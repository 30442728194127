<script>
import InputField from '@/components/general/InputField'
import SectionBlock from '@/components/general/SectionBlock'
import ProfilePictureManage from '@/components/settings/components/ProfilePictureManage'
import SelectField from '@/components/general/SelectField'
import Breadcrumbs from '@/components/general/Breadcrumbs'
import ConfirmationModal from '@/components/general/ConfirmationModal.vue'
export default {
  name: 'NewProfileForm',
  components: {
    ProfilePictureManage,
    SectionBlock,
    InputField,
    SelectField,
    Breadcrumbs,
    ConfirmationModal
  },
  data () {
    return {
      showConfirmModal: false,
      showImportResumeModal: false,
      attemptingSave: false,
      tempArea: null,
      availableRoles: [
        { id: 'participant', text: this.$t('modal.invite.member:roles.participant') },
        { id: 'manager', text: this.$t('modal.invite.member:roles.manager') },
        { id: 'admin', text: this.$t('modal.invite.member:roles.admin') }
      ],
      approvalFlowSteps: [],
      dependents: [],
      formData: {
        id: null,
        name: null,
        lastName: null,
        fullName: null,
        email: null,
        role: null,
        approvalFlow: null,
        dependent: this.$t('admin.center.users:bookmark.default')
      }
    }
  },
  created () {
    this.synchronizeUser().then(() => {
      this.getUser()
      this.getApprovalFlow()
      this.getDependents()
    })
  },
  computed: {
    disableChangeRole () {
      const user = this.$store.getters.getUser
      return user.id === this.$route.params.userId
    },
    breadcrumbsPaths () {
      const user = this.formData
      const path = [
        {
          text: 'admin.center:menu.3:title',
          href: this.$router.resolve({ name: 'business.admin.center.users' }).href
        },
        {
          text: user.fullName,
          href: this.$route.path
        }
      ]
      return path
    }
  },
  methods: {
    getUser () {
      this.$store.dispatch('attemptGetUser', this.$route.params.userId)
        .then(({ data }) => {
          this.defineUserProfile(data.user[0])
        })
    },
    defineUserProfile (user) {
      const name = user.fullName.split(' ')
      this.formData.id = user.id
      this.formData.name = name[0]
      this.formData.lastName = name.slice(1).join(' ')
      this.formData.fullName = user.fullName
      this.formData.email = user.email
      this.formData.avatar = user.avatar.indexOf('/empty') === -1 ? user.avatar : '/assets/images/profile-avatar.jpg'
      this.formData.role = user.role
      this.formData.approvalFlow = user.approvalFlowSteps
      this.formData.dependent = user.dependent === null ? '' : user.dependent
    },
    setSteps (flowName, flowId, step) {
      this.approvalFlowSteps.push({
        flowName: flowName,
        flowId: flowId,
        id: step.id,
        text: `${flowName} - ${step.name}`
      })
      if (step.children) {
        step.children.forEach((s) => {
          this.setSteps(flowName, flowId, s)
        })
      }
    },
    getApprovalFlow () {
      this.$store.dispatch('attemptGetApprovalFlow')
        .then(({ data }) => {
          if (data && data.approvalFlows.length) {
            data.approvalFlows.forEach((f) => {
              this.setSteps(f.name, f.id, f.step)
            })
          }
        })
    },
    getDependents () {
      this.$store.dispatch('attemptGetDependents')
        .then(({ data }) => {
          this.dependents = data.map(({ _id, businessName }) => ({ id: _id, text: businessName }))
          this.dependents.sort((a, b) => a.text.localeCompare(b.text))
          this.dependents.unshift({ id: '', text: this.$t('admin.center.users:bookmark.default') })
        })
    },
    updateUser () {
      this.attemptingSave = true
      const lxPayload = {
        workspaceId: this.selectedWorkspace.lxID,
        email: this.formData.email,
        role: this.formData.role
      }
      const payload = {
        id: this.formData.id,
        role: this.formData.role,
        dependent: this.formData.dependent,
        approvalFlowSteps: this.formData.approvalFlow
      }
      Promise.all([
        this.$store.dispatch('attemptChangeMemberProfileByEmail', lxPayload),
        this.$store.dispatch('attemptUpdateUsers', payload)
      ])
        .then(() => {
          this.$store.dispatch('attemptSetFeedbackMsg', {
            type: 'success',
            title: this.$t('settings.admincenter.users.labels:update.success')
          })
          this.$emit('userDeleted')
        })
        .catch(() => {
          this.$store.dispatch('attemptSetFeedbackMsg', {
            type: 'error',
            title: this.$t('settings.admincenter.users.labels:update.error')
          })
        })
        .finally(() => {
          this.attemptingSave = false
          this.showConfirmationModal()
        })
    },
    showConfirmationModal () {
      this.showConfirmModal = !this.showConfirmModal
    }
  }
}
</script>
<template>
  <div class="user-profile--container center-small">
    <div class="default-header">
      <breadcrumbs :items="breadcrumbsPaths" />
      <h2>{{ formData.name }} {{ formData.lastName }}</h2>
      <h2 class="color-loading" v-if="!formData.name">.</h2>
    </div>
    <div class="user-profile">
      <profile-picture-manage :readOnly="true" :isNotMe="true" :userProfile="formData" v-model="formData.avatar" />
      <section-block title="account.form:personal.data.title">
        <input-field v-model="formData.name" disabled outlined persistent-placeholder
          :label="$t('settings.profile.labels:name.personal')"></input-field>
        <input-field v-model="formData.lastName" disabled outlined persistent-placeholder
          :label="$t('settings.profile.labels:lastName')"></input-field>
        <input-field v-model="formData.email" disabled outlined persistent-placeholder
          :label="$t('settings.profile.labels:email')"></input-field>
      </section-block>

      <section-block title="account.form:contact.data.title">
        <select-field v-model="formData.role" outlined :items="availableRoles" item-value="id"
          :disabled="disableChangeRole" :label="$t('modal.invite.member:roles.userType')">
        </select-field>

        <select-field v-model="formData.approvalFlow" multiple outlined :items="approvalFlowSteps" item-value="id" :label="$t('modal.invite.member:approvalflow')"></select-field>
        <select-field v-model="formData.dependent" outlined :items="dependents" item-value="id" :label="$t('admin.center.bookmark:form.bookmark.title')"></select-field>

      </section-block>
    </div>
    <div class="profile-btn--actions">
      <v-btn class="btn transform-unset" :disabled="attemptingSave" :color="getPrimaryColor" :dark="!attemptingSave"
        @click="showConfirmationModal()">
        <v-icon v-if="!attemptingSave" class="mr-2" size="20" color="#fff">mdi-content-save-outline</v-icon>
        {{ $t(attemptingSave? 'global:wait' : 'global:save.changes') }}
      </v-btn>
    </div>
    <confirmation-modal
        :show="showConfirmModal"
        :title="$t('admin.center.users:modal.title')"
        :description="$t('admin.center.users:modal.description')"
        :confirmText="$t('global:confirm')"
        :cancelText="$t('global:cancel')"
        :btnConfirmText="false"
        @cancel="showConfirmationModal()"
        @confirm="updateUser()"
      />
  </div>
</template>
<style lang="scss">
.user-profile--container {
  .profile-btn--actions {
    text-align: right;
    margin-top: 40px;
    margin-bottom: 100px;
  }
}

.color-loading {
  color: #f8f8f8
}
</style>
